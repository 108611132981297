
function FourOneFour() {
  return (
    <div id="404" className="bg-homepage-bg" >
      <div className="h-screen w-screen flex justify-center items-center break-all">
          <span className="text-homepage-text text-[2rem] break-all p-10">😞 This page does not exist 😞 </span>
      </div>
    </div>
  );
}

export default FourOneFour;
